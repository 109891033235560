/* Watsi styling for jQuery Calendars Picker v2.0.0. */
.calendars {
	background-color: #fff;
	color: #212529;
	border: none;
    -moz-border-radius: 0.25em;
    -webkit-border-radius: 0.25em;
    border-radius: 0.25em;
	font-family: 'Plex';
	/* font-size: 90%; */
}
.calendars-rtl {
	direction: rtl;
}
.calendars-popup {
	z-index: 1000;
}
.calendars-disable {
	position: absolute;
	z-index: 100;
	background-color: white;
	opacity: 0.5;
	filter: alpha(opacity=50);
}
.calendars a {
	color: #212529;
	text-decoration: none;
}
.calendars a.calendars-disabled {
	color: #868e96;
	cursor: auto;
}
.calendars button {
    margin: 0.25em;
    padding: 0.125em 0em;
	background-color: #4dadf7;
	color: #fff;
    border: none;
    -moz-border-radius: 0.25em;
    -webkit-border-radius: 0.25em;
    border-radius: 0.25em;
    font-weight: bold;
}
.calendars-nav, .calendars-ctrl {
	float: left;
	width: 100%;
	background-color: #fff;
	/* font-size: 90%; */
	font-weight: bold;
}
.calendars-ctrl {
	background-color: #dee2e6;
}
.calendars-cmd {
	width: 30%;
}
.calendars-cmd:hover {
	background-color: #e8f7ff;
}
button.calendars-cmd:hover {
	background-color: #72c3fc;
}
.calendars-cmd-prevJump, .calendars-cmd-nextJump {
	width: 8%;
}
a.calendars-cmd {
	height: 1.5em;
}
button.calendars-cmd {
	text-align: center;
}
.calendars-cmd-prev, .calendars-cmd-prevJump, .calendars-cmd-clear {
	float: left;
	padding-left: 2%;
}
.calendars-cmd-current, .calendars-cmd-today {
	float: left;
	width: 35%;
	text-align: center;
}
.calendars-cmd-next, .calendars-cmd-nextJump, .calendars-cmd-close {
	float: right;
	padding-right: 2%;
	text-align: right;
}
.calendars-rtl .calendars-cmd-prev, .calendars-rtl .calendars-cmd-prevJump,
.calendars-rtl .calendars-cmd-clear {
	float: right;
	padding-left: 0%;
	padding-right: 2%;
	text-align: right;
}
.calendars-rtl .calendars-cmd-current, .calendars-rtl .calendars-cmd-today {
	float: right;
}
.calendars-rtl .calendars-cmd-next, .calendars-rtl .calendars-cmd-nextJump,
.calendars-rtl .calendars-cmd-close {
	float: left;
	padding-left: 2%;
	padding-right: 0%;
	text-align: left;
}

.calendars-cmd-next,  .calendars-cmd-prev, .calendars-cmd-today {
	display: none;
}

.calendars-month-nav {
	float: left;
	text-align: center;
}
.calendars-month-nav div {
	float: left;
	width: 12.5%;
	margin: 1%;
	padding: 1%;
}
.calendars-month-nav span {
	color: #868e96;
}
.calendars-month-row {
	clear: left;
}
.calendars-month {
	float: left;
	width: 15em;
	border: 2px solid #4dadf7;
	text-align: center;
}
.calendars-month-header, .calendars-month-header select, .calendars-month-header input {
	height: 1.5em;
	background-color: #4dadf7;
	color: #fff;
	font-weight: bold;
}
.calendars-month-header select, .calendars-month-header input {
	height: 1.4em;
	border: none;
}
.calendars-month-header input {
	position: absolute;
	display: none;
}
.calendars-month table {
	width: 100%;
	border-collapse: collapse;
}
.calendars-month thead {
	border-bottom: 1px solid #aaa;
}
.calendars-month th, .calendars-month td {
	margin: 0em;
	padding: 0em;
	font-weight: normal;
	text-align: center;
}
.calendars-month th {
	border: 1px solid #fff;
	border-bottom: 1px solid #ced4da;
}
.calendars-month td {
	border: 1px solid #ced4da;
}
.calendars-month td:last-child {
	border-right: none;
}

.calendars-month td:first-child {
	border-left: none;
}

.calendars-month tr:last-child > td{
	border-bottom: none;
}

.calendars-month td.calendars-week * {
	background-color: #dee2e6;
	color: #212529;
	border: none;
}
.calendars-month a {
	display: block;
	width: 100%;
	padding: 0.125em 0em;
	background-color: #e8f7ff;
	color: #000;
	text-decoration: none;
}
.calendars-month span {
	display: block;
	width: 100%;
	padding: 0.125em 0em;
}
.calendars-month td span {
	color: #868e96;
}
.calendars-month td .calendars-other-month {
	background-color: #fff;
}
.calendars-month td .calendars-today {
	background-color: #fab005;
	color: #fff;
	font-weight: bold;
}
.calendars-month td .calendars-highlight {
	background-color: #72c3fc;
	color: #fff;
	font-weight: bold;
}
.calendars-month td .calendars-selected {
	background-color: #4297d7;
	color: #fff;
	font-weight: bold;
}
.calendars-status {
	clear: both;
	text-align: center;
}
.calendars-clear-fix {
	clear: both;
}
